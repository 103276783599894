import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

interface Props {}

export const Error404Page: React.FC<Props> = () => {
  return (
    <section className={styles.errorWrapper}>
      <div className={styles.error}>
        <span className={styles.error__icon}></span>
        <h3>
          <span>죄송합니다.</span> 페이지를 찾을 수 없습니다.
        </h3>
        <span className={styles.error__text}>
          찾으시려는 페이지의 주소가 잘못되었거나 변경되어 페이지를 찾을 수 없습니다. 서비스 이용에
          불편을 드려서 죄송합니다.
        </span>
        <span className={styles.error__code}>에러코드 : 404</span>
        <Link to="/">마미톡 첫 화면 가기</Link>
      </div>
      <footer>
        <ul>
          <li>
            <span>E-mail</span>help@mmtalk.kr
          </li>
        </ul>
        <p className={styles.copyright}>ⓒ Mommytalk Inc.</p>
      </footer>
    </section>
  );
};
