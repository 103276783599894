import * as React from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';
import { QUERY_CACHE_HOSPITAL_INFO, QUERY_CACHE_KEYWORD } from '@gqls/local.gql';

import styles from './style.module.scss';

interface Props {}

export const Header: React.FC<Props> = () => {
  const client = useApolloClient();
  const [hospitalName, setHospitalName] = React.useState('');
  const [keyword, setKeyword] = React.useState('');

  const { data: keywordCache } = useQuery(QUERY_CACHE_KEYWORD, {
    fetchPolicy: 'cache-only',
  });

  const { data } = useQuery(QUERY_CACHE_HOSPITAL_INFO, {
    fetchPolicy: 'cache-only',
  });

  React.useEffect(() => {
    if (!data) {
      return;
    }

    console.log(data);

    setHospitalName(data.hospitalName);
  }, [data]);

  React.useEffect(() => {
    if (!keywordCache) {
      return;
    }

    setKeyword(keywordCache.keyword.trim());
  }, [keywordCache]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    client.writeQuery({
      query: QUERY_CACHE_KEYWORD,
      data: {
        keyword,
      },
    });
  };

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  return (
    <nav className="container">
      <div className={styles.nav__logoWrap}>
        <span className={styles.nav__logo}></span>
        <span className={styles.nav__logoTitle}>LIBRE 센터</span>
      </div>
      <div className={styles.nav__search}>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            placeholder="고객명 or 연락처 검색"
            value={keyword}
            onChange={onChangeKeyword}
          />
          <button type="submit" />
        </form>
      </div>
      <div className={styles.nav__menu}>
        <span className={styles.nav__menu__hospital}>{hospitalName}</span>
        <Link to="/logout" className={styles.nav__menu__logout}>
          로그아웃
        </Link>
      </div>
    </nav>
  );
};
