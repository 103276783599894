import * as React from "react";
import { Route as ReactRoute, RouteProps, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_CACHE_HOSPITAL_INFO } from "@gqls/local.gql";

interface Props extends RouteProps {
  component?: React.Component | any;
  layout?: React.ComponentType<RouteProps> | React.ComponentType<any>;
  authRequired?: boolean;
  authNotAllow?: boolean;
}

export const Route: React.FC<Props> = ({
  component,
  layout,
  authRequired = false,
  authNotAllow = false,
  location,
  ...rest
}) => {
  const { data } = useQuery(QUERY_CACHE_HOSPITAL_INFO, {
    fetchPolicy: "cache-only",
  });

  const routeComponent: any = (props: any) => {
    // 인증이 필요한 라우터 접근시, 미인증 상태이면 /login 으로 전환
    if (authRequired && !data?.hospitalSeq) {
      return <Redirect to={{ pathname: "/login", state: location }} />;
    }

    // 인증상태에서 접근 제한할 페이지
    if (authNotAllow && Number(data?.hospitalSeq) > 0) {
      return <Redirect to="/" />;
    }

    if (layout) {
      return React.createElement(
        layout,
        props,
        React.createElement(component, props)
      );
    }

    return React.createElement(component, props);
  };

  return <ReactRoute {...rest} render={routeComponent} />;
};

