import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps<void> {
  component: React.Component;
}

export const ErrorLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  return <section>{children}</section>;
};

