import * as React from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";

import { QUERY_CACHE_HOSPITAL_INFO } from "@gqls/local.gql";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {}

const MUTATION_LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const LogoutPage: React.FC<Props> = ({ history }) => {
  const client = useApolloClient();

  const [logout] = useMutation(MUTATION_LOGOUT, {
    fetchPolicy: "no-cache",
    onError(err) {
      console.log(err);
    },
    onCompleted() {
      client.writeQuery({
        query: QUERY_CACHE_HOSPITAL_INFO,
        data: {
          hospitalSeq: 0,
          hospitalName: "",
        },
      });

      history.replace("/login");
    },
  });

  React.useEffect(() => {
    // 기존 캐시 초기화
    client.cache.reset();

    logout();
  }, []);

  return null;
};

