import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { persistCache } from 'apollo-cache-persist';
import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types';
import { QUERY_CACHE_HOSPITAL_INFO, QUERY_CACHE_KEYWORD } from '@gqls/local.gql';
import { BrowserRouter } from 'react-router-dom';

interface IGraphQLError {
  error?: string;
  statusCode?: number;
}

const resetLoginCache = () => {
  cache.writeQuery({
    query: QUERY_CACHE_HOSPITAL_INFO,
    data: {
      hospitalSeq: 0,
      hospitalName: '',
    },
  });
};

const resetKeywordCache = () => {
  cache.writeQuery({
    query: QUERY_CACHE_KEYWORD,
    data: {
      keyword: '',
    },
  });
};

// prepare store
// const history = createBrowserHistory();
const cache = new InMemoryCache({
  typePolicies: {
    TelemedicineRequest: {
      keyFields: ['seq'],
    },
  },
});
resetLoginCache();

(async () => {
  await persistCache({
    cache,
    storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>,
  });
  resetKeywordCache();

  // error link
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      let gqlError: IGraphQLError;
      if (typeof graphQLErrors[0].message === 'object') {
        gqlError = Object(graphQLErrors[0].message);
      } else {
        gqlError = {
          error: String(graphQLErrors[0].message),
        };
      }
      console.log(gqlError.error);

      // 인증오류시 캐시 초기화
      if (gqlError.error === 'Unauthorized') {
        resetLoginCache();
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const apiLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || '/graphql',
    credentials: 'include',
  });

  const link = ApolloLink.from([errorLink, apiLink]);

  // apollo client
  const client = new ApolloClient({
    cache,
    link,
  });

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
