import * as React from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_LIBRE_APPLIES } from '@gqls/libre.gql';
import { ILibreApply } from '@interfaces/libre.interface';
import { IPagination } from '@interfaces/pagination.interface';
import { Pager } from '@components';

import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './style.module.scss';
import { QUERY_CACHE_KEYWORD } from '@gqls/local.gql';

interface Props {}

export const MainPage: React.FC<Props> = () => {
  const [pagination, setPagination] = React.useState<IPagination>({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageStartIdx, setPageStartIdx] = React.useState(0);
  const [applies, setApplies] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');

  const { data: keywordCache } = useQuery(QUERY_CACHE_KEYWORD, {
    fetchPolicy: 'cache-only',
  });

  const { data } = useQuery(QUERY_LIBRE_APPLIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: currentPage,
      keyword,
    },
  });

  React.useEffect(() => {
    if (!data) {
      return;
    }

    const { libreApplies } = data;

    setPagination(libreApplies.meta);
    setApplies(libreApplies.items);
  }, [data]);

  React.useEffect(() => {
    const { totalCount, page, limit } = pagination;
    setPageStartIdx(Number(totalCount) - (Number(page) - 1) * Number(limit) || 0);
  }, [pagination]);

  React.useEffect(() => {
    if (!keywordCache) {
      return;
    }

    setKeyword(keywordCache.keyword.trim());
  }, [keywordCache]);

  const onPageUpdate = (page: number) => {
    if (page < 1 || page > Number(pagination?.totalPage)) {
      return;
    }

    setCurrentPage(page);
  };

  return (
    <main className="container">
      {applies && applies.length > 0 ? (
        <React.Fragment>
          <table className={styles.niptList}>
            <thead>
              <tr>
                <td>번호</td>
                <td>고객명</td>
                <td>연락처</td>
                <td>분만 예정일</td>
                <td>신청 시간</td>
              </tr>
            </thead>
            <tbody>
              {applies.map((item: ILibreApply, index: number) => (
                <tr key={index} className={index & 1 ? styles.bgGray : ''}>
                  <td>{pageStartIdx - index}</td>
                  <td>{item.realname}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.babyDueDate}</td>
                  <td>
                    {item.createdDate} <span>{item.createdTime}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pager pagination={pagination} onPageUpdate={onPageUpdate} />
        </React.Fragment>
      ) : (
        <div className={styles.noData}>
          <span className={styles.noData_icon}></span>
          <span className={styles.noData_text}>
            {keyword.trim().length > 0 && applies.length === 0
              ? '검색하신 결과가 없습니다.'
              : '신청 기록이 없습니다.'}
          </span>
        </div>
      )}
    </main>
  );
};
