import * as React from 'react';
import { IPagination } from '@interfaces/pagination.interface';

import styles from './style.module.scss';

interface Props {
  pagination: IPagination;
  onPageUpdate: (page: number) => void;
  pageSize?: number;
}

const genNumbers = (pagination: IPagination, pageSize: number = 5) => {
  // 현재 페이지 기준 그룹 인덱스 계산
  const pageGroupIdx: number = Math.floor((Number(pagination.page) - 1) / pageSize);

  // 그룹 내 첫번째 페이지
  const firstPage: number = pageSize * pageGroupIdx + 1;

  // 그룹 내 마지막 페이지
  const totalPage = Number(pagination.totalPage);
  let lastPage: number = firstPage + (pageSize - 1);
  lastPage = lastPage > totalPage ? totalPage : lastPage;

  const pages: number[] = [];
  for (let i = firstPage; i <= lastPage; i++) {
    pages.push(i);
  }
  return pages;
};

export const Pager: React.FC<Props> = ({ pagination, onPageUpdate, pageSize = 5 }) => {
  const onClickPrev = () => {
    onPageUpdate(Number(pagination.page) - 1);
  };

  const onClickNext = () => {
    onPageUpdate(Number(pagination.page) + 1);
  };

  return (
    <ul className={styles.paging}>
      <li onClick={onClickPrev}>이전</li>
      {genNumbers(pagination, pageSize).map((page: number) => {
        if (page === pagination.page) {
          return (
            <li key={page} className={styles.paging__active}>
              {page}
            </li>
          );
        }
        return (
          <li key={page} onClick={() => onPageUpdate(page)}>
            {page}
          </li>
        );
      })}
      <li onClick={onClickNext}>다음</li>
    </ul>
  );
};
