import { gql } from '@apollo/client';

export const QUERY_LIBRE_APPLIES = gql`
  query LibreApplies($page: Float, $keyword: String) {
    libreApplies(page: $page, keyword: $keyword) {
      meta {
        page
        limit
        totalCount
        totalPage
        offset
      }
      items {
        phoneNumber
        realname
        babyDueDate
        createdDate
        createdTime
      }
    }
  }
`;
