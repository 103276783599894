import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Header } from '@components';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<void> {
  component: React.Component;
}

export const DefaultLayout: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child as React.ReactElement<{}>, { ...props });
  });

  return (
    <div className={['conatiner', styles.layout].join(' ')}>
      <Header {...props} />
      {children}
    </div>
  );
};
