import * as React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { LayoutRoute } from './app/components';
import * as Layouts from './app/layouts';
import * as Pages from './app/pages';

interface Props {}

const App: React.FC<Props> = () => {
  return (
    <Router>
      <Switch>
        <LayoutRoute
          exact
          path="/"
          component={Pages.Main}
          layout={Layouts.Default}
          authRequired={true}
        />

        {/* 로그인 */}
        <LayoutRoute
          exact
          path="/login"
          component={Pages.Login}
          layout={Layouts.Blank}
          authNotAllow={true}
        />

        {/* 로그아웃 */}
        <LayoutRoute exact path="/logout" component={Pages.Logout} layout={Layouts.Blank} />

        {/* 404 */}
        <LayoutRoute exact path="*" component={Pages.Error404} layout={Layouts.Error} />
      </Switch>
    </Router>
  );
};

export default App;
