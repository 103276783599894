import * as React from 'react';
import { gql, useLazyQuery, useApolloClient } from '@apollo/client';
import { confirmAlert } from 'react-confirm-alert';

import styles from './style.module.scss';
import { QUERY_CACHE_HOSPITAL_INFO } from '@gqls/local.gql';

interface Props {}

const QUERY_LOGIN = gql`
  query Login($id: String!, $password: String!) {
    login(id: $id, password: $password) {
      status
      hospitalSeq
      hospitalName
    }
  }
`;

export const LoginPage: React.FC<Props> = () => {
  const client = useApolloClient();
  const [isValid, setIsValid] = React.useState(false);
  const [id, setId] = React.useState('');
  const [password, setPassword] = React.useState('');

  const alertOnLoginFailed = () => {
    confirmAlert({
      title: '로그인 실패',
      message: '아이디와 비밀번호를 확인해 주세요.',
      buttons: [
        {
          label: '확인',
          onClick: () => {},
        },
      ],
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
  };

  const [login, { loading }] = useLazyQuery(QUERY_LOGIN, {
    fetchPolicy: 'no-cache',
    onError: (err) => {
      alertOnLoginFailed();
    },
    onCompleted: ({ login }) => {
      if (!login.status) {
        alertOnLoginFailed();
        setPassword('');
        return;
      }

      // 로그인 처리
      client.writeQuery({
        query: QUERY_CACHE_HOSPITAL_INFO,
        data: {
          hospitalSeq: login.hospitalSeq,
          hospitalName: login.hospitalName,
        },
      });
    },
  });

  React.useEffect(() => {
    // 기존 캐시 초기화
    client.cache.reset();
  }, []);

  React.useEffect(() => {
    setIsValid(id.trim().length > 0 && password.trim().length > 0);
  }, [id, password]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    // 로그인
    login({
      variables: {
        id,
        password,
      },
    });
  };

  return (
    <section className={styles.login}>
      <div className={styles.loginForm}>
        <form onSubmit={submit}>
          <div className={styles.login__logoWrap}>
            <span className={styles.login__logo}></span>
            <span className={styles.login__logoTitle}>LIBRE 센터</span>
          </div>
          <input
            type="text"
            placeholder="아이디를 입력해주세요."
            autoFocus={true}
            value={id}
            onChange={(e) => setId(e.currentTarget.value)}
          />
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요."
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <button type="submit" className={styles.login__submit} disabled={!isValid || loading}>
            로그인하기
          </button>
        </form>
      </div>
    </section>
  );
};
