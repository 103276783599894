import { gql } from "@apollo/client";

export const QUERY_CACHE_HOSPITAL_INFO = gql`
  query cacheHospitalInfo {
    hospitalSeq @client
    hospitalName @client
  }
`;

export const QUERY_CACHE_KEYWORD = gql`
  query cacheKeyword {
    keyword @client
  }
`;

